<template>
    <div class="page-title" v-html="title" />
</template>

<script>
export default {
    name: "PageTitle",
    props: {
        title: String,
    }
};
</script>

<style lang="scss">
.page-title {
    padding: 0 15px;
    max-width: 350px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;

    @media (max-height: 620px) {
        font-size: 20px;
        line-height: 28px;
    }

    & > span {
        background-color: #6f4aec;
        background-image: linear-gradient(90deg, #b73ee9, #7549ec);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
