<template>
    <div class="page">
        <div class="page__award" v-if="$slots.award">
            <slot name="award" />
        </div>
        <div class="page__close" v-if="$slots.close"> 
            <slot name="close"/>
        </div>
        <div class="page__spacer" v-if="$slots.spacerTop">
            <slot name="spacerTop"/>
        </div>
        <div class="page__logo" v-if="$slots.logo">
            <slot name="logo" />
        </div>
        <div class="page__title" v-if="$slots.title"> 
            <slot name="title"/>
        </div>
        <div class="page__img" v-if="$slots.img"> 
            <slot name="img"/>
        </div>
        <div class="page__content" v-if="$slots.content"> 
            <slot name="content"/>
        </div>
         <div class="page__price" v-if="$slots.price">
            <slot name="price" />
        </div>
        <div class="page__form" v-if="$slots.form"> 
            <slot name="form"/>
        </div>
        <div class="page__spacer" v-if="$slots.spacerBottom">
            <slot name="spacerBottom"/>
        </div>
        <div class="page__button" v-if="$slots.button"> 
            <slot name="button"/>
        </div>
        <div class="page__button-with-links" v-if="$slots.buttonWithLinks"> 
            <slot name="buttonWithLinks"/>
        </div>
    </div>
</template>

<script>
 export default{
    name:"Page",
    setup(props, { slots }) {
        const hasSlot = name => !!slots[name]
        return { hasSlot }
    }
 }
</script>

<style lang="scss">
.page {
    $class: &;
    flex-grow: 1;
    padding-top: 68px;
    padding-bottom: 116px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;

    @media (max-height: 620px) {
        padding-top: 60px;
    }

    &_with-links {
        padding-bottom: 132px;
    }

    &_tinkoff {
        padding-bottom: 188px;

        @media (max-height: 710px) {
            padding-top: 60px;
        }
    }

    &__award {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 20px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        max-width: 675px;
        background: #FFFFFF;
        border-bottom: 1px solid #EFEFF2;
        z-index: 999;
        box-sizing: border-box;

        @media (max-height: 620px) {
            height: 60px;
        }

        #{$class}_tinkoff & {
            @media (max-height: 710px) {
                 height: 60px;
            }
        }
    }

    &__logo {
        padding: 24px 0 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-height: 620px) {
            padding: 15px 0 12px 0;
        }

        #{$class}_tinkoff & {
            @media (max-height: 710px) {
                padding: 15px 0 12px 0;
            }
        }
    }

    &__title {
        text-align: center;
    }

    &__img {
        margin: 24px auto;
        padding: 0 20px;
        box-sizing: border-box;
        width: 100%;

        @media (max-height: 620px) {
            margin: 20px auto;
        }

        #{$class}_tinkoff & {
            @media (max-height: 710px) {
                margin: 20px auto;
            }
        }
    }

    &__content {
        width: 100%;
    }

    &__price {
        position: absolute;
        bottom: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;

        #{$class}_form & {
            bottom: 216px;
        }

        #{$class}_links & {
            bottom: 147px;
        }

        #{$class}_tinkoff & {
            bottom: 195px;
        }
    }

    &__form {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 55px;
        right: 5px;
        z-index: 999;
    }

    &__button {
        padding: 0 20px;
        position: fixed;
        bottom: 0;        
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 16px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.56) 38.54%, #FFFFFF 68.75%);
        box-sizing: border-box;

        & .button + .button {
            margin-top: 12px;
        }
    }

    &__button-with-links {
        padding: 0 20px;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-top: 12px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.56) 38.54%, #FFFFFF 68.75%);
        box-sizing: border-box;
    }

    &__links {
        padding: 16px 5px;
    }

    &__spacer {
        flex-grow: 1;
    }
}
</style>

