import {createI18n} from "vue-i18n";

const messages_all = {
    //pt_BR
    en: {
        apple: {
            script_lang: "en_US"
        },
        button: {
            next: "Next",
            continue: "Continue",
            get_app: "Get app",
            sign_in: "Sign in with Apple",
            sign_in_2: "Sign in",
            yes: "Yes",
            yes_long: "Yes, I’m sure",
            no: "No",
            no_long: "No, I’m not",
            go_to_instories: "Перейти в Instories"
        },
        text: {
            boost_you: "Boost your <br> Social media <br>with Instories",
            best_templates: "Best templates <br>for you targets",
            download_app: "Download app to start",
            unsuccess: "Unsuccessful :( <br>Try again",
            success: "Congrats!",
            success_subtitle: "Your trial started. <br>Download app to start.",
            welcome: "Welcome back!",
            welcome_subtitle: "You already have an account. <br>Download app to start.",
            upset: "We are very upset :(",
            unsubscribe: "You sure you want to unsubscribe?",
            subscription_cancelled: "Subscription is cancelled ☹️",
            subscription_restart: "Want to restart subscription?",
            page_title: "Grow your Instagram <br/> Account with <span> Instories </span>",
            auth: "Авторизуйтесь, чтобы получить доступ к подписке",
            success_pay: "Поздравляем! Подписка <span> Instories </span> оформлена!",
            go_to_app: "Перейдите по кнопке в приложение, чтобы не проходить повторную авторизацию",
            templates: "500+ templates"
        },
        link: {
            terms_conditions: "EULA",
            privacy_policy: "Privacy policy",
            accept_text: "By continuing, you accept"
        },
        common: {
            and: "&",
            then: "Then",
            year: "year",
            month: "month",
            week: "week"
        },
        price: {
            three_days_free: "3 days FREE trial",
            days_free: "days FREE trial",
            free_trial_days: "-days FREE trial",
            description: "Instories PRO subscription",
        },
        signup: {
            title: 'Create unique Reels, Stories, Posts',
            description: 'To link a Pro license to your account, you need to log in. It\'s safe and only takes one minute.'
        }
    }
};

const messages_tinkoff = {
    ru: {
        apple: {
            script_lang: "ru_RU"
        },
        button: {
            next: "Далее",
            continue: "Продолжить",
            get_app: "Скачать приложение",
            sign_in: "Войти с Apple",
            sign_in_2: "Войти",
            yes: "Yes",
            yes_long: "Yes, I’m sure",
            no: "No",
            no_long: "No, I’m not",
            go_to_instories: "Перейти в Инсторис"
        },
        text: {
            boost_you: "Создавай моушн для SMM <br>в Инсторис",
            best_templates: "Лучшие шаблоны <br>для ваших задач",
            download_app: "Скачайте приложение чтобы начать",
            unsuccess: "Не удалось :( <br>Попробуйте ещё раз",
            success: "Поздравляем!",
            success_subtitle: "Ваш пробный период открыт. <br>Скачайте приложение, чтобы начать.",
            welcome: "Добро пожаловать!",
            welcome_subtitle: "У вас уже есть аккаунт. <br>Скачайте приложение, чтобы начать.",
            upset: "We are very upset :(",
            unsubscribe: "You sure you want to unsubscribe?",
            subscription_cancelled: "Subscription is cancelled ☹️",
            subscription_restart: "Want to restart subscription?",
            page_title: "Развивайте свой Instagram вместе с <span> Инсторис </span>",
            auth: "Авторизуйтесь, чтобы получить доступ к подписке",
            success_pay: "Поздравляем! Подписка <span> Инсторис </span> оформлена!",
            go_to_app: "Перейдите по кнопке в приложение, чтобы не проходить повторную авторизацию",
            templates: "500+ шаблонов",
            terms_checkbox_label: "Я соглашаюсь на автоматические списания, обработку персональных данных и принимаю"
        },
        link: {
            terms_conditions: "Условия использования",
            privacy_policy: "Конфиденциальность",
            accept_text: "Продолжая, вы принимаете нашу"
        },
        common: {
            and: "и",
            then: "Далее",
            year: "год",
            month: "месяц",
            week: "неделя",
            discount: "скидка",
            terms_of_offer: "Условия лицензионного соглашения"
        },
        price: {
            three_days_free: "3 дня бесплатно",
            days_free: "дня бесплатно",
            free_trial_days: "-дневная пробная версия",
            description: "Instories PRO subscription"
        },
        signup: {
            title: 'Создавайте уникальные\nReels, Stories, Posts',
            description: 'Авторизуйтесь, чтобы подключить Pro лицензию к вашему аккаунту. Это безопасно и займёт одну минуту.'
        },
        subscription: {
            title: 'Оформи подписку и получи неограниченный доступ ко всем премиум инструментам Инсторис',
            trial_title: 'Пробный период',
            price_label_year: 'Ежегодно {price} RUB\n({perMonth} RUB / мес)',
            price_label_month: 'Ежемесячно {price} RUB',
            trial_days: "0 дней бесплатно | {days} день бесплатно | {days} дня бесплатно | {days} дней бесплатно",
            success: "Подписка оформлена!\nТеперь у вас есть доступ\nк премиум инструментам:"
        }
    },
};

let lang = "en";
let messages = messages_all;

if (process.env.VUE_APP_TINKOFF === "true") {
    lang = "ru";
    messages = messages_tinkoff;
}

export const i18n = createI18n({
    locale: navigator.language,
    pluralizationRules: {
        ru: function (choice, choicesLength) {

            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    },
    fallbackLocale: lang,
    messages,
});
