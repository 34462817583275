<template>
    <div class="awards-slider" v-for="i in [currentIndex]" :key="i">
        <img class="awards-slider__item" :src="currentImg" width="314" height="88" />
    </div>
</template>

<script>
export default {
    name: "AwardsSlider",
    data() {
        return {
            images: [
                "/assets/svg/awards_slider_1.svg",
                "/assets/svg/awards_slider_2.svg",
                "/assets/svg/awards_slider_3.svg",
                "/assets/svg/awards_slider_4.svg",
                "/assets/svg/awards_slider_5.svg",
                "/assets/svg/awards_slider_6.svg",
                "/assets/svg/awards_slider_7.svg",
            ],
            timer: null,
            currentIndex: 0
        };
    },

    mounted: function() {
        this.startSlide();
    },

    methods: {
        startSlide: function() {
        this.timer = setInterval(this.next, 2500);
        },

        next: function() {
        this.currentIndex += 1;
        },

        prev: function() {
        this.currentIndex -= 1;
        }
    },

    computed: {
        currentImg: function() {
        return this.images[Math.abs(this.currentIndex) % this.images.length];
        }
    }
};
</script>

<style lang="scss">
.awards-slider {
    text-align: center;
}
</style>
