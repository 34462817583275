<template>
    <div class="button" :class="{outlined, loading}">
        <div class="button__icon">
            <slot name="icon"/>
        </div>
        <span class="button__text">
            <span class="loader"><IcLoader/></span>
            {{ title }}
        </span>
        <span v-if="subtitle" class="button__text-subtitle">
            {{ subtitle }}
        </span>
    </div>
</template>

<script>
import IcLoader from "@/components/IcLoader.vue";

export default {
    name: "Button",
    components: {IcLoader},
    props: {
        title: String,
        subtitle: String,
        outlined: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss">
.button {
    $class: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 316px;
    height: 56px;
    background: linear-gradient(180deg, #7a64e2 0%, #7c15ff 100%);
    border-radius: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    transition: 300ms;

    &__text-subtitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.1px;
    }

    &:active {
        transform: scale(0.95);
        transition: all 0.3s ease-in;
    }

    &_apple,
    &_google {
        background: #fff;
        color: #000;
    }

    &_purple {
        background: #674AF1;
        box-shadow: 0px 4px 8px rgba(76, 53, 188, 0.12), 0px 4px 14px rgba(122, 96, 243, 0.3);
        border-radius: 16px;
        letter-spacing: -0.34px;
        font-weight: 500;
        width: 100%;
        max-width: 388px;
    }

    &_black {
        background: #141417;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14), 0px 4px 24px rgba(0, 0, 0, 0.06);
        border-radius: 16px;
        letter-spacing: -0.34px;
        font-weight: 500;
        line-height: 24px;
        color: #fff;
        width: 100%;
        max-width: 388px;
    }

    &_gray {
        background: #E7E8ED;
        border-radius: 16px;
        letter-spacing: -0.34px;
        font-weight: 500;
        color: #141417;
        width: 100%;
        max-width: 388px;
    }

    &_white {
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14), 0px 4px 24px rgba(0, 0, 0, 0.06);
        border-radius: 16px;
        letter-spacing: -0.34px;
        font-weight: 500;
        line-height: 24px;
        color: #141417;
        width: 100%;
        max-width: 388px;
        position: relative;

        &.outlined {
            border-radius: 20px;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                background: transparent;
                pointer-events: none;
                border-width: 1px;
                border-style: solid;
                transition: 300ms;
            }

            &:before {
                inset: -4px;
                border-radius: 22px;
                border-color: rgba(255, 255, 255, 0.40);
            }

            &:after {
                inset: -10px;
                border-radius: 26px;
                border-color: rgba(255, 255, 255, 0.10);
            }

            &:active {
                &:before {
                    inset: -6px;
                    border-radius: 24px;
                }

                &:after {
                    inset: -14px;
                    border-radius: 30px;
                }
            }
        }
    }

    &_no-shadow {
        box-shadow: none;
    }

    &_disabled {
        cursor: default;

        &:active {
            transform: none;
        }
    }

    &_no-bg {
        background: none;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    &_gray-bg {
        padding: 0 22px;
        width: auto;
        background: #35384C;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    &__text {
        display: flex;

        #{$class}_apple &,
        #{$class}_google & {
            position: relative;
            padding-left: 29px;
        }

        #{$class}_apple-white & {
            position: relative;
            padding-left: 24px;
        }

        #{$class}_pay & {
            padding-left: 22px;
            font-size: 22px;
            font-weight: 500;
        }

        &:before {
            position: absolute;
            left: 0;
            top: 50%;
            display: block;

            #{$class}_apple & {
                margin-top: -10px;
                width: 17px;
                height: 21px;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg fill='none' height='21' viewBox='0 0 17 21' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.464 3.54725c.6133-.73218 1.0604-1.75472 1.0604-2.777248 0-.138863-.0127-.277725-.0383-.39134-1.0093.037872-2.223.669068-2.95122 1.514868-.57492.64381-1.09873 1.65372-1.09873 2.68888 0 .15148.02556.30297.03833.35346.06388.01263.16609.02525.26829.02525.90709 0 2.04413-.60594 2.72123-1.41387zm.7155 1.62848c-1.5203 0-2.7596.90892-3.53893.90892-.84321 0-1.95471-.85843-3.27062-.85843-2.50407 0-5.046464 2.04507-5.046464 5.90798 0 2.3985.945414 4.9359 2.108014 6.577.99652 1.3886 1.86528 2.5248 3.11731 2.5248 1.23926 0 1.78862-.8206 3.3345-.8206 1.57139 0 1.91639.7953 3.29619.7953 1.3542 0 2.2613-1.2371 3.1173-2.449.9582-1.3886 1.3542-2.752 1.3798-2.8151-.0894-.0253-2.6829-1.073-2.6829-4.0144 0-2.55001 2.0441-3.69878 2.1591-3.78715-1.3543-1.91883-3.4112-1.96932-3.9733-1.96932z' fill='%23000'/%3E%3C/svg%3E");
            }

            #{$class}_apple-white & {
                margin-top: -10px;
                width: 15px;
                height: 19px;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='19' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2042 3.06059C11.954 2.2367 12.4628 1.13045 12.3286 0C11.231 0.0479465 9.89155 0.636147 9.11608 1.46069C8.4198 2.16678 7.80353 3.31934 7.96419 4.40241C9.19632 4.49629 10.4273 3.86137 11.2042 3.06059Z' fill='white'/%3E%3Cpath d='M11.151 5.00606C9.63344 4.91559 8.34315 5.86803 7.61844 5.86803C6.89333 5.86803 5.78356 5.05165 4.58325 5.07365C3.02098 5.09662 1.57138 5.98064 0.778589 7.38666C-0.852038 10.1994 0.348267 14.3716 1.93397 16.6624C2.70402 17.7958 3.63206 19.0437 4.8549 18.9988C6.01028 18.9535 6.46314 18.2501 7.86755 18.2501C9.27092 18.2501 9.67886 18.9988 10.9019 18.9761C12.1702 18.9534 12.9631 17.8422 13.7332 16.7078C14.6166 15.4159 14.9782 14.1684 15.0009 14.0999C14.9782 14.0772 12.5553 13.147 12.5328 10.3576C12.5099 8.02209 14.4351 6.91117 14.5257 6.84227C13.4385 5.23309 11.7399 5.05165 11.151 5.00606Z' fill='white'/%3E%3C/svg%3E%0A");
            }

            #{$class}_google & {
                margin-top: -8px;
                width: 18px;
                height: 19px;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg fill='none' height='19' viewBox='0 0 18 19' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-rule='evenodd' fill-rule='evenodd'%3E%3Cpath d='m17.64 9.88534c0-.63818-.0573-1.25182-.1636-1.84091h-8.4764v3.48137h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.61496z' fill='%234285f4'/%3E%3Cpath d='m9 18.6806c2.43 0 4.4673-.8059 5.9564-2.1804l-2.9087-2.2582c-.8059.54-1.8368.8591-3.0477.8591-2.34409 0-4.32818-1.5832-5.03591-3.7105h-3.006815v2.3318c1.480905 2.9414 4.524545 4.9582 8.042725 4.9582z' fill='%2334a853'/%3E%3Cpath d='m3.96409 11.3905c-.18-.54-.28227-1.1168-.28227-1.71001 0-.59318.10227-1.17.28227-1.71v-2.33182h-3.006817c-.609546 1.215-.957273 2.58955-.957273 4.04182 0 1.45231.347727 2.82681.957273 4.04181z' fill='%23fbbc05'/%3E%3Cpath d='m9 4.26021c1.3214 0 2.5077.45409 3.4405 1.34591l2.5813-2.58136c-1.5586-1.45228-3.5959-2.344096-6.0218-2.344096-3.51818 0-6.56182 2.016816-8.042725 4.958186l3.006815 2.33181c.70773-2.12727 2.69182-3.71045 5.03591-3.71045z' fill='%23ea4335'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &__icon {
        position: absolute;
        inset: 0 auto 0 0;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        width: 0;
        margin-right: 0;
        transition: 300ms;
        line-height: 1;
    }

    &.loading {
        pointer-events: none;
        opacity: 0.7;

        .loader {
            opacity: 1;
            width: 18px;
            margin-right: 6px;
        }
    }
}
</style>
