<template>
    <div class="tink-subscription-page">
        <div class="bg-video">
            <video autoPlay loop muted :controls=false playsInline>
                <source src='/assets/video/instories-ru-bg2.mp4' type='video/mp4'/>
            </video>
        </div>
        <div class="content">
            <div class="title">
                {{ $t('subscription.title') }}
            </div>
            <div class="subscription-options" :class="{hidden: !priceTextYear || !priceTextMonth}">
                <RadioButton
                    v-model="selectedSubscription"
                    class="yearly"
                    option-value="year"
                    :label-text="priceTextYear"
                    :side-text="discountTextYear"/>
                <RadioButton
                    v-model="selectedSubscription"
                    class="monthly"
                    option-value="month"
                    :label-text="priceTextMonth"
                    @update:model-value="handleChangeMonthly"/>
            </div>
            <div class="checkbox-wrap" :class="{hidden: !isTrialAvailable}">
                <Switcher v-if="isTrialAvailable" v-model="isTrialSelected" @update:model-value="handleChangeTrial"/>
            </div>
            <Button
                class="button_white"
                :title="$t('button.continue')"
                :subtitle="isTrialSelected ? trialSubtitleText : ''"
                outlined
                :loading="isLoading"
                @click="pressContinue"/>
            <div class="terms-checkbox" @click="userIsAgree = !userIsAgree">
                <icon-check :active="userIsAgree" :color="!userIsAgree && highlightCheckbox ? '#FFF' : '#81818F'"/>
                <label for="terms-checkbox-input">
                    {{ $t('text.terms_checkbox_label') }}
                    <a @click.stop href="https://instoris.ru/terms" target="_blank" ref="noopener noreferrer">
                        {{ $t('common.terms_of_offer') }}
                    </a>
                </label>
            </div>
        </div>
    </div>
    <!--
        <Page class="page_tinkoff">
            <template v-slot:close>
                <CloseButton v-show="closeButtonIsShow" v-on:click="closeButtonOnClick"/>
            </template>

            <template v-slot:award>
                <AwardBlock/>
            </template>

            <template v-slot:logo>
                 <Logo/>
            </template>

            <template v-slot:title>
                <PageTitle :title="$t('text.page_title')"/>
            </template>

            <template v-slot:img>
                <ImageBlock class="image-block_tinkoff"/>
            </template>

            <template v-slot:price>
                <TrialPrice :text="trialText" class="trial-price_dark-text" />
                <PriceBlock :text="priceText" :cost="convertedPrice" :time="period" />
            </template>

            <template v-slot:button>
                <Button class="button_purple button_no-shadow" :title="$t('button.continue')" v-on:click="pressContinueButton"/>
                <Button class="button_gray" :title="buttonPriceText" v-on:click="pressPriceButton"/>
                <LinksBlockTinkoff class="links-block-tinkoff_after-button" />
            </template>
        </Page>
    -->
</template>

<script>
/*
import AwardBlock from "@/components/AwardBlock";
import Page from "@/components/Page";
import Logo from "@/components/Logo";
import PageTitle from "@/components/PageTitle";
import ImageBlock from "@/components/ImageBlock";
import TrialPrice from "@/components/TrialPrice";
import PriceBlock from "@/components/PriceBlock";
import CloseButton from "@/components/CloseButton";
import PageText from "@/components/PageText";
import LinksBlockTinkoff from "@/components/LinksBlockTinkoff";
*/
import Button from "@/components/Button";
import Switcher from "@/components/Switcher";
import RadioButton from "@/components/RadioButton";
import IconCheck from "@/components/IconCheck";

export default {
    name: "PaymentTinkoff",
    components: {
        IconCheck,
        RadioButton,
        Switcher,
        Button,
        /*
                AwardBlock,
                Page,
                Logo,
                PageTitle,
                ImageBlock,
                TrialPrice,
                PriceBlock,
                CloseButton,
                PageText,
                LinksBlockTinkoff
        */
    },
    data() {
        return {
            selectedSubscription: 'year',
            isTrialAvailable: false,
            isTrialSelected: true,
            priceTextYear: "",
            discountTextYear: "",
            priceTextMonth: "",
            trialSubtitleText: "",
            userIsAgree: true,
            highlightCheckbox: false,
            isLoading: false
            /*
                        trialText: "",
                        priceText: "",
                        convertedPrice: "",
                        period: "",
                        buttonPriceText: "",
                        closeButtonIsShow: false,
            */
        };
    },
    methods: {
        setAuthDataForLocalEnv() {
            if (process.env.NODE_ENV !== 'local') {
                return;
            }
            const data = this.$route.query;
            this.createDefaultOnelinkIfNeeded();
            var onelink = new URL(localStorage.getItem('onelink'));
            onelink.searchParams.set("deep_link_value", data.oauth_id);
            this.setToStorage("onelink", onelink.href);
            var status = data.status;
            if (this.isTinkoff) {
                status = data.robokassa_status;
                // status = data.tinkoff_status;
            }
            if (data.oauth_id && status) {
                this.setToStorage("status", status);
                this.setToStorage("oauth_id", data.oauth_id);
            }
        },
        fillData: function (data) {
            if (data.year.price < (data.month.price * 12)) {
                const discount = 1 - (data.year.price / (data.month.price * 12));
                this.discountTextYear = `${this.$t('common.discount')} ${Math.round(discount * 100)}%`;
            }
            this.priceTextYear = this.$t('subscription.price_label_year', {
                price: data.year.price,
                perMonth: Math.round(data.year.price / 12)
            });
            this.priceTextMonth = this.$t('subscription.price_label_month', {price: data.month.price});
            this.isTrialAvailable = !!data.year.trial_period_days;
            if (this.isTrialAvailable) {
                const text = this.$tc('subscription.trial_days', data.year.trial_period_days);
                this.trialSubtitleText = data.year.trial_period_days + ' ' + text;
            }

            /*
            let year = data.year;
            let month = data.month;
            if (year.trial_period_days) {
                this.isTrialAvailable = true;
                this.trialText = year.trial_period_days + " " + this.$t('price.days_free');
            }
            this.priceText = this.$t('common.then');
            this.convertedPrice = year.price + "₽";
            this.period = this.$t('common.year');
            this.buttonPriceText = month.price + "₽ / " + this.$t('common.month');
            */
        },
        handleChangeTrial(value) {
            if (value === 'year') {
                return;
            }
            this.selectedSubscription = 'year';
        },
        handleChangeMonthly(value) {
            if (!value) {
                return;
            }
            this.isTrialSelected = false;
        },
        async pressContinue() {
            const product = {
                year: process.env.VUE_APP_ROBOKASSA_YEAR_ID,
                month: process.env.VUE_APP_ROBOKASSA_MONTH_ID
            }[this.selectedSubscription];
            /*
                        const product = {
                            year: process.env.VUE_APP_TINKOFF_YEAR_ID,
                            month: process.env.VUE_APP_TINKOFF_MONTH_ID
                        }[this.selectedSubscription];
            */
            if (!product) {
                return;
            }
            if (!this.userIsAgree) {
                this.highlightCheckbox = true;
                setTimeout(() => {
                    this.highlightCheckbox = false;
                }, 500);
                return;
            }
            this.isLoading = true;
            try {
                await this.subscribeRobokassaPlan(product, this.isTrialSelected, this.userIsAgree);
            } finally {
                this.isLoading = false;
            }

        },

        /*
                showCloseButton: function () {
                    setTimeout(() => {
                        this.closeButtonIsShow = true;
                    }, 5000);
                },
                closeButtonOnClick: function () {
                    this.setToStorage("skip", true);
                    this.routeTo('subscribed');
                },
                pressContinueButton: function () {
                    this.subscribeTinkoffPlan(process.env.VUE_APP_TINKOFF_YEAR_ID);
                },
                pressPriceButton: function () {
                    this.subscribeTinkoffPlan(process.env.VUE_APP_TINKOFF_MONTH_ID);
                },
        */
    },
    created: function () {
        this.setAuthDataForLocalEnv();
        this.routeIfCannotSubscribe();
        // this.showCloseButton();
    },
    async mounted() {
        this.gtag_event('open_' + this.$route.name);
        const data = await this.loadRobokassaPlan();
        this.fillData(data);
    },
};
</script>

<style lang="scss">
.tink-subscription-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #151518;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bg-video {
        position: absolute;
        z-index: 1;
        inset: 0;
        display: flex;
        justify-content: center;

        video {
            object-fit: cover;
            width: 100%;
            max-width: $container-max-width;
            object-position: top center;
            position: relative;
            z-index: 1;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            z-index: 2;
        }
    }

    .content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        max-width: $content-max-width;
        padding: 20px 0 46px;

        .title {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #EFEFF2;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: -0.3px;
            margin-bottom: 40px;
            white-space: pre-line;
        }

        .subscription-options {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-bottom: 44px;
            transition: 300ms;

            .radio-button {
                width: 100%;
                max-width: calc(100vw - 40px);

                &.yearly {
                    .main-info {
                        .label {
                            font-size: 15px;
                            line-height: 20px;
                            letter-spacing: -0.23px;
                        }
                    }
                }
            }

            &.hidden {
                opacity: 0;
                pointer-events: none;
            }
        }

        .checkbox-wrap {
            display: flex;
            width: 100%;
            max-width: calc(100vw - 40px);
            height: 32px;
            margin-bottom: 28px;
            padding-left: 16px;

            .checkbox-label {
                color: #FFF;
                font-size: 17px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.34px;
                flex-grow: 1;
            }

            &.hidden {
                opacity: 0;
                pointer-events: none;
            }
        }

        .button {
            width: 100%;
            max-width: calc(100vw - 40px);
            height: 62px;
            margin-bottom: 24px;
        }

        .terms-checkbox {
            width: 100%;
            max-width: calc(100vw - 40px);
            display: flex;
            gap: 8px;
            cursor: pointer;

            svg {
                flex-shrink: 0;
            }

            input {
                display: none;
            }

            label {
                color: #A1A1AA;
                font-size: 9px;
                line-height: 12px;
                letter-spacing: 0.2px;
                cursor: pointer;

                a {
                    color: #EFEFF2;
                    text-decoration: none;

                    &:hover,
                    &:focus,
                    &:active {
                        color: #FFF;
                    }
                }
            }
        }

        @media (max-width: 389px) {
            .title {
                font-size: 17px;
                font-weight: 500;
                line-height: 24px;
            }

            .subscription-options {
                margin-bottom: 36px;

                .radio-button {
                    max-width: calc(100vw - 32px);

                    &.yearly,
                    &.monthly {
                        .main-info {
                            .label {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }

            .checkbox-wrap {
                padding-left: 12px;
            }

            .button {
                height: 56px;
            }
        }

        @media (min-width: 744px) {
            padding-bottom: 62px;
            gap: 12px;

            .title {
                letter-spacing: 0.5px;
            }

            .subscription-options {
                margin-bottom: 96px;
            }

            .checkbox-wrap {
                margin-bottom: 46px;
                padding-left: 0;
            }
        }
    }
}
</style>
