<template>
    <div class="restart-subscription">
        <img src="/assets/images/logo_dark.png" class="restart-subscription__logo">
        <div class="restart-subscription__spacer"></div>
        <div class="restart-subscription__subtitle">{{ $t('text.subscription_cancelled') }}</div>
        <div class="restart-subscription__title">{{ $t('text.subscription_restart') }}</div>
        <div class="unsubscribe__spacer restart-subscription__spacer_button-top"></div>
        <div class="restart-subscription__button">
            <Button :title="$t('button.yes')" v-on:click="pressYesButton" />
        </div>        
        <div class="unsubscribe__spacer restart-subscription__spacer_button-bottom"></div>
    </div>
</template>

<script>
import Button from "@/components/Button"

export default{
    name:"Resubscribe",
    components: {
        Button
    },
    methods: {
        pressYesButton: function () {
            this.routeTo('purchase');
        }
    },
    mounted: function() {
        this.gtag_event('open_'+this.$route.name);
    }
}
</script>

<style lang="scss">
.restart-subscription {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #252737;

    &__spacer {
        flex-grow: 1;

        &_button-top {
            @media (min-width:1200px) {
                display: none;
            }
        }

        &_button-bottom {
            display: none;

            @media (min-width:1200px) {
                display: flex
            }
        }
    }

    &__logo {
        display: block;
        margin: 34px auto;
        width: 94px;
    }

    &__title {
        margin: 24px 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #fff;

        @media (min-width:1200px) {
            margin: 32px 0;
            font-size: 24px;
            line-height: 33px;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #fff;
    }

    &__button {
        margin: 40px auto;
        display: flex;
        justify-content: center;

        @media (min-width:1200px) {
            margin: 0;
        }
    }
}
</style>