<template>
    <div class="radio-button" :class="{selected, disabled}" @click="handleClick">
        <div class="main-info">
            <IconCheck :active="selected"/>
            <div class="label">
                {{labelText}}
            </div>
        </div>
        <div v-if="sideText" class="side-info">
            {{sideText}}
        </div>
    </div>
</template>

<script>
import IconCheck from "@/components/IconCheck";

export default {
    name: "RadioButton",
    components: {IconCheck},
    props: {
        modelValue: String,
        optionValue: String,
        labelText: String,
        sideText: String,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        selected() {
            return this.modelValue === this.optionValue;
        },
    },
    methods: {
        handleClick() {
            this.$emit('update:model-value', this.optionValue);
        }
    }
};
</script>

<style lang="scss">
.radio-button {
    background: transparent;
    height: 68px;
    width: 100%;
    border-radius: 16px;
    transition: 300ms;
    display: flex;

    .main-info {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 0 16px;
        gap: 12px;

        .label {
            color: #FFF;
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.34px;
            white-space: pre-line;
        }
    }

    .side-info {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 16px;
        color: #FFF;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        width: 80px;

        &:before {
            content: '';
            position: absolute;
            display: block;
            background-color: rgba(255, 255, 255, 0.3);
            left: 0;
            top: 8px;
            bottom: 8px;
            width: 1px;
        }
    }

    &.selected {
        background-color: #2C2C30;
    }

    &.disabled {
        //background-color: #2C2C30;
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>
