<template>
    <div class="unsubscribe">
        <img class="unsubscribe__logo" src="/assets/images/logo_dark.png">
        <div class="unsubscribe__spacer"></div>
        <div class="unsubscribe__title">{{ $t('text.upset') }}</div>
        <img class="unsubscribe__img" src="/assets/images/cat.gif">
        <div class="unsubscribe__spacer"></div>
        <div class="unsubscribe__info">
            <div class="unsubscribe__info-title">{{ $t('text.unsubscribe') }}</div>
            <div class="unsubscribe__info-buttons">
                 <Button class="button_gray-bg" :title="$t('button.yes_long')" v-on:click="pressYesButton" />
                 <Button class="button_no-bg" :title="$t('button.no_long')" v-on:click="pressNoButton" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button"

export default{
    name:"Unsubscribe",
    components: {
        Button
    },
    data() {
        return {
            cancelData: {},
            inProgress: false,
        }
    },
    methods: {
        pressYesButton: function () {
            if (this.inProgress) {
                return;
            }
            this.inProgress = true;
            const request = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(this.cancelData)
            };
            var url = process.env.VUE_APP_STRIPE_CANCEL_REQUEST_URI;
            if (this.isTinkoff) {
                url = process.env.VUE_APP_ROBOKASSA_CANCEL_REQUEST_URI;
                // url = process.env.VUE_APP_TINKOFF_CANCEL_REQUEST_URI;
            }
            fetch(url, request)
                .then(response => response.json())
                .then(data => {
                    if (data.code) {
                        this.setToStorage("code", data.code);
                    }
                    this.setToStorage("status", "nt");
                    this.inProgress = false;
                    this.routeTo('resubscribe');
                });
        },
        pressNoButton: function () {
            localStorage.removeItem("subscribed");
            this.routeTo('subscribed');
        },
    },
    created: function() {
        let url = new URL(window.location.href);
        let oauth_id = url.searchParams.get("oauth") || localStorage.getItem('oauth_id');
        let sub = url.searchParams.get("sub");
        if (oauth_id && sub) {
            this.setToStorage("status", "su");
            this.setToStorage("oauth_id", oauth_id);
            if (this.isTinkoff) {
                this.cancelData.product_id = sub;
            } else {
                this.cancelData.sub = sub;
            }
            this.cancelData.oauth_id = oauth_id;
        } else {
            this.routeTo('home');
        }
    },
    mounted: function() {
        this.gtag_event('open_'+this.$route.name);
    }
}
</script>

<style lang="scss">
.unsubscribe {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #252737;

    &__spacer {
        flex-grow: 1;

        @media (min-width:1200px) {
            display: none
        }
    }

    &__logo {
        display: block;
        margin: 34px auto;
        width: 94px;
    }

    &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #fff;

        @media (min-width:1200px) {
            font-size: 24px;
            line-height: 33px;
        }
    }

    &__img {
        margin: 34px auto;
        display: block;
        width: 100%;
        max-width: 340px;
        border-radius: 24px;
    }

    &__info-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #fff;

        @media (min-width:1200px) {
            font-size: 18px;
            line-height: 25px;
        }
    }

    &__info-buttons {
        margin: 32px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .button + .button {
            margin-top: 10px;
        }
    }
}
</style>
