<template>
    <router-view v-slot="{ Component }">
        <transition>
            <component :is="Component"/>
        </transition>
    </router-view>
</template>

<script>
if (process.env.VUE_APP_NEWDESIGN === "true") {
    import("./style/commonNew.scss");
} else {
    import("./style/common.scss");
}

export default {
    name: "App",
    components: {},

};
</script>

<style lang="scss">
@import "./style/normalize.scss";

#app {
    overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-out;
}

.slide-enter-to {
    position: absolute;
    right: 0;
}

.slide-enter-from {
    position: absolute;
    right: -100%;
}

.slide-leave-to {
    position: absolute;
    left: -100%;
}

.slide-leave-from {
    position: absolute;
    left: 0;
}

</style>
