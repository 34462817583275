<template>
    <div class="image-block">
        <div class="image-block__text" v-html="$t('text.templates')" />
    </div>
</template>

<script>

export default {
    name: "ImageBlock"
};
</script>

<style lang="scss">
.image-block {
    position: relative;
    margin: 0 auto;
    display: flex;
    height: 212px;
    width: 100%;
    max-width: 675px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    overflow: hidden;
    background-image: url("/assets/images/imgBlockBg.jpg");
    background-position-x: center;
    background-position-y: 30%;
    background-size: cover;

    @media (max-height: 620px) {   
        height: 124px;
    }

    &_tinkoff {
        @media (max-height: 710px) {   
            height: 124px;
            background-position-y: 25%;
        }
    }

    &__text {
        position: absolute;
        left: 12px;
        bottom: 12px;
        padding: 4px 12px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.36px;
        color: #FFFFFF;
        background: rgba(26, 28, 41, 0.7);
        backdrop-filter: blur(14px);
        border-radius: 8px;
    }
}
</style>
