<template>
    <video
        class="video-item"
        preload="auto"
        playsinline=""
        autoplay=""
        loop=""
        muted=""
        >
        <source :src="`/assets/video/${name}.mp4`" type="video/mp4" />
    </video>
</template>

<script>
export default {
    name: "VideoItem",
    props: {
        name: String,
    }
};
</script>

<style lang="scss">
.video-item {
    display: block;
    width: 100%;
    max-width: 675px;
    height: 100%;
    margin: 0 auto;
    pointer-events: none;
    object-fit: cover;
}
</style>
