<template>
    <div class="text-block" v-html="text" />
</template>

<script>
export default {
    name: "TextBlock",
    props: {
        text: String
    }
};
</script>

<style lang="scss">
.text-block {
    margin: 0 auto;
    padding: 0 15px 20px 15px;
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.41px;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #81818F;

    &_small {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}
</style>
