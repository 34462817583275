import {createWebHistory, createRouter} from "vue-router";

import Home from "@/views/Home";
import Onboarding from "@/views/Onboarding";
import Signup from "@/views/Signup";
import Purchase from "@/views/Purchase";
import Subscribed from "@/views/Subscribed";
import Payment from "@/views/Payment";
import Unsuccess from "@/views/Unsuccess";
import Unsubscribe from "@/views/Unsubscribe";
import Resubscribe from "@/views/Resubscribe";
import PaymentTinkoff from "@/views/PaymentTinkoff";
import SuccessTinkoff from "@/views/SuccessTinkoff";

const routes = [
    {
        path: "/:code?",
        name: "home",
        component: Home
    },
    {
        path: "/:code?/onboarding",
        name: "onboarding",
        component: Onboarding
    },
    {
        path: "/:code?/signup",
        name: "signup",
        component: Signup
    },
    {
        path: "/:code?/purchase",
        name: "purchase",
        component: Purchase
    },
    {
        path: "/:code?/secure-payment",
        name: "secure-payment",
        component: Payment
    },
    {
        path: "/:code?/subscribed",
        name: "subscribed",
        component: Subscribed
    },
    {
        path: "/:code?/signup-fail",
        name: "signup-fail",
        component: Unsuccess
    },
    {
        path: "/:code?/payment-fail",
        name: "payment-fail",
        component: Unsuccess
    },
    {
        path: "/:code?/server-fail",
        name: "server-fail",
        component: Unsuccess
    },
    {
        path: "/:code?/unsubscribe",
        name: "unsubscribe",
        component: Unsubscribe
    },
    {
        path: "/:code?/resubscribe",
        name: "resubscribe",
        component: Resubscribe
    }
];

const routesForTinkoff = [
    {
        path: "/",
        name: "signup",
        component: Signup
    },
    {
        path: "/purchase",
        name: "purchase",
        component: PaymentTinkoff
    },
    {
        path: "/success",
        name: "success",
        component: SuccessTinkoff
    },
    {
        path: "/subscribed",
        name: "subscribed",
        component: SuccessTinkoff
        // component: Subscribed
    },
    {
        path: "/signup-fail",
        name: "signup-fail",
        component: Unsuccess
    },
    {
        path: "/payment-fail",
        name: "payment-fail",
        component: Unsuccess
    },
    {
        path: "/server-fail",
        name: "server-fail",
        component: Unsuccess
    },
    {
        path: "/unsubscribe",
        name: "unsubscribe",
        component: Unsubscribe
    },
    {
        path: "/resubscribe",
        name: "resubscribe",
        component: Resubscribe
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: process.env.VUE_APP_TINKOFF === "true" ? routesForTinkoff : routes,
});

export default router;
