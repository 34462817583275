<template>
    <SubscriptionPage>
        <template v-slot:video>
            <VideoItem name="slider_video_1" />
        </template>

        <template v-slot:text>
            <PageText :title="$t('text.unsuccess')" />
        </template>

        <template v-slot:button>
            <Button :title="$t('button.continue')" v-on:click="nextSlide" />
        </template>
    </SubscriptionPage>
</template>

<script>
import SubscriptionPage from "@/components/SubscriptionPage"
import VideoItem from "@/components/VideoItem"
import PageText from "@/components/PageText"
import Button from "@/components/Button"

export default{
    name:"Unsuccess",
    components: {
        SubscriptionPage,
        VideoItem,
        PageText,
        Button
    },
    methods: {
        nextSlide: function () {
            this.routeByStatus('signup');
        },
    },
    mounted: function() {
        this.gtag_event('open_'+this.$route.name);
    }
}
</script>

<style lang="scss">
 
</style>