<template>
    <div class="subscription-page">        
        <div class="subscription-page__video">
            <slot name="video" />
        </div>
        <div class="subscription-page__gradient"></div>
        <div class="subscription-page__close" v-if="$slots.close"> 
            <slot name="close"/>
        </div>
        <div class="subscription-page__text">
            <slot name="text" />
        </div>
        <div class="subscription-page__price">
            <slot name="price" />
        </div>
        <div class="subscription-page__form" v-if="$slots.form"> 
            <slot name="form"/>
        </div>
        <div class="subscription-page__button" v-if="$slots.button">
            <slot name="button" />
        </div>
        <div class="subscription-page__links" v-if="$slots.links"> 
            <slot name="links"/>
        </div>
    </div>
</template>

<script>
export default{
    name:"SubscriptionPage",
    setup(props, { slots }) {
        const hasSlot = name => !!slots[name]
        return { hasSlot }
    }
}
</script>

<style lang="scss">
.subscription-page {
    $class: &;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &__video {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 169px;
            content: "";
            background: linear-gradient(180deg, rgba(54, 43, 103, 0) 0%, #04071a 100%);
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 170px;
            content: "";
            background: #04071a;
        }
    }

    &__text {
        position: absolute;
        bottom: 251px;
        left: 0;
        right: 0;

        #{$class}_form & {
            bottom: 286px;
        }
    }

    &__price {
        position: absolute;
        bottom: 123px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;

        #{$class}_form & {
            bottom: 216px;
        }

        #{$class}_links & {
            bottom: 147px;
        }
    }

    &__form {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
    }

    &__button {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 316px;
        height: 56px;

        #{$class}_links & {
            bottom: 74px;
        }
    }

    &__links {
        position: absolute;
        bottom: 13px;
        width: 100%;
        height: 48px;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 999;
    }
}
</style>

