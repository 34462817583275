// Google Tag Manager
if (process.env.VUE_APP_GTM_ID && process.env.VUE_APP_GTM_ID != '') {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',process.env.VUE_APP_GTM_ID);
}
// End Google Tag Manager

// Google Analytics
// if (process.env.VUE_APP_GTAG_ID && process.env.VUE_APP_GTAG_ID != '') {
//     (function(w,d,s,l,i){w[l]=w[l]||[];w.gtag=function(){w[l].push(arguments);}
//     w.gtag('js',new Date());w.gtag('config',i);
//     var f=d.getElementsByTagName(s)[0],
//     j=d.createElement(s);j.async=true;j.src=
//     'https://www.googletagmanager.com/gtag/js?id='+i;f.parentNode.insertBefore(j,f);
//     })(window,document,'script','dataLayer',process.env.VUE_APP_GTAG_ID);
// }
// End Google Analytics