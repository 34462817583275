<template>
    <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="item in reviews" :key="item.id">
            <Review 
                :imgName="item.imgName" 
                :name="item.name" 
                :post="item.post" 
                :text="item.text" 
            />
        </Slide>
        <template #addons>
            <Pagination />
        </template>
    </Carousel>
</template>

<script>
import Review from "@/components/Review"
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Pagination, Slide } from 'vue3-carousel';

export default {
    name: "ReviewSlider",
    components: {
        Review,
        Carousel,
        Pagination,
        Slide
    },
    data() {
        return {
            reviews: [
                {
                    imgName: "review_avatar_1",
                    name: "Kristin Watson",
                    post: "Content-maker",
                    text: "OMG. This is by far the best app I’ve ever used to great stories and post for my social medias. Templates are great and modern. And the app is so easy to use and the UX is at top level. When there is a problem, their customer service replies very quickly."
                },
                {
                    imgName: "review_avatar_2",
                    name: "Jessica Joy",
                    post: "SMM managers",
                    text: "The Best app for your stories & promotions. Very friendly platform, you’ll find a lot of options to create and promote your content 🔥🙌🏼"
                },
                {
                    imgName: "review_avatar_3",
                    name: "Dylan Clark",
                    post: "Graphic designer",
                    text: " I am a graphic designer & I have been using this app to create tasteful stories on my Instagram and tiktok. I must say it is very easy to use and many templates are free to use. I love this app and will not be deleting any time soon."
                }
            ],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 675px and up
                675: {
                    itemsToShow: 0,
                    snapAlign: 'center',
                }
            },
        };
    },
};
</script>

<style lang="scss">
.review-slider {
    display: flex;
    justify-content: center;
}
.carousel {
    &__slide--visible {
        transform: rotateY(0);
    }

    &__slide {
        align-items: inherit;

    }

    &__slide + &__slide { 
        @media (min-width: 675px) {
            margin-top: 20px;
        }
    }

    &__track {
        
        @media (min-width: 675px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translateX(0)!important;
        }
    }

    &__pagination {
        margin: 12px 0 0 0;
        padding: 0;

        @media (min-width: 675px) {
            display: none;
        }
    }

    &__pagination-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
    }

    &__pagination-button {
        padding: 0;
        margin: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #EFEFF2;

        &--active {
            background-color: #7A64E2;
        }
    }

}
</style>
