<template>
    <div class="successful-payment-page">
        <div class="content">
            <div class="logo-ru">
                <img v-if="isLightTheme" class="name" src="/assets/images/logo_dark_ru.png" alt=""/>
                <img v-else class="name" src="/assets/images/logo_white_ru.png" alt=""/>
                <div class="suffix-wrap">
                    <img class="suffix" src="/assets/images/suffix_pro_gold.png" alt=""/>
                </div>
            </div>
            <div class="title">
                {{ $t('subscription.success') }}
            </div>
            <div class="feature-list">
                <div v-for="{title, subtitle, icon, bgImageUrl} in features" :key="title" class="feature-item">
                    <div class="icon-wrap" v-html="icon" :style="{backgroundImage: `url(${bgImageUrl})`}"/>
                    <div class="text">
                        <div class="title">{{ title }}</div>
                        <div class="subtitle">{{ subtitle }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="redirect-btn">
            <Button :class="isLightTheme ? 'button_black' : 'button_white'" :title="$t('button.go_to_instories')"
                    @click="goToInstories"/>
        </div>
    </div>
    <!--
        <Page class="page_with-links">
            <template v-slot:award>
                <AwardBlock/>
            </template>

            <template v-slot:spacerTop />

            <template v-slot:logo>
                 <Logo/>
            </template>

            <template v-slot:title>
                <PageTitle :title="$t('text.success_pay')"/>
            </template>

            <template v-slot:spacerBottom />

            <template v-slot:button>
                <TextBlock class="text-block_small" :text="$t('text.go_to_app')"/>
                <Button class="button_purple" :title="$t('button.go_to_instories')" v-on:click="goToInstories"/>
            </template>
        </Page>
    -->
</template>

<script>
import AwardBlock from "@/components/AwardBlock";
import Page from "@/components/Page";
import Logo from "@/components/Logo";
import PageTitle from "@/components/PageTitle";
import Button from "@/components/Button";
import PageText from "@/components/PageText";
import TextBlock from "@/components/TextBlock";
import {features} from "@/utils/premiumFeatureList";

export default {
    name: "SuccessTinkoff",
    components: {
        AwardBlock,
        Page,
        Logo,
        PageTitle,
        Button,
        PageText,
        TextBlock
    },
    data: () => ({
        features
    }),
    methods: {
        goToInstories: function () {
            if (this.sourceIsApp) {
                console.log('goToInstories', `instoris://action?id=webPaywall&oauth=${localStorage.getItem('oauth_id')}&result=1`);
                window.location = `instoris://action?id=webPaywall&oauth=${localStorage.getItem('oauth_id')}&result=1`;
                return;
            }
            console.log('goToInstories', localStorage.getItem('onelink'));
            window.location = localStorage.getItem('onelink');
        }
    },
    mounted: function () {
        this.gtag_event('open_' + this.$route.name);
        this.createDefaultOnelinkIfNeeded();

        let url = new URL(window.location.href);
        let success = url.searchParams.get("Success");
        let error = parseInt(url.searchParams.get("ErrorCode"));

        if (localStorage.getItem('skip')) {
            var onelink = new URL(localStorage.getItem('onelink'));
            onelink.searchParams.set("deep_link_sub2", false);
            this.setToStorage("onelink", onelink.href);
        }
        if (error) {
            this.routeTo('payment-fail');
        } else if (success === "true") {
            localStorage.removeItem("data");
            localStorage.setItem('status', "su");
            var onelink = new URL(localStorage.getItem('onelink'));
            onelink.searchParams.set("deep_link_sub2", true);
            this.setToStorage("onelink", onelink.href);
            // this.routeTo('success');
        } else if (localStorage.getItem('status') === "su") {
            var onelink = new URL(localStorage.getItem('onelink'));
            onelink.searchParams.set("deep_link_sub2", true);
            this.setToStorage("onelink", onelink.href);
        }
    },
};
</script>

<style lang="scss">
.successful-payment-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #151518;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding-bottom: 80px;

    .content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: $content-max-width;

        .logo-ru {
            display: flex;
            gap: 8px;
            height: 168px;
            align-items: center;
            flex-shrink: 0;

            img.name {
                height: 40px;
            }

            .suffix-wrap {
                height: 40px;

                img.suffix {
                    height: 26px;
                    position: relative;
                    top: 8px;
                }
            }
        }

        > .title {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #EFEFF2;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.38px;
            margin-bottom: 28px;
        }

        .feature-list {
            display: flex;
            flex-direction: column;
            gap: 6px;
            width: 100%;
            max-width: calc(100vw - 40px);

            .feature-item {
                display: flex;
                gap: 16px;
                width: 100%;
                padding: 4px 0;
                align-items: center;

                .icon-wrap {
                    width: 52px;
                    height: 52px;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: cover;
                    flex-shrink: 0;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 2px;

                    .title {
                        color: #EFEFF2;
                        font-size: 17px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.34px;
                    }

                    .subtitle {
                        color: #A1A1AA;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .redirect-btn {
        position: fixed;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(28, 28, 32, 0) 0%, rgba(28, 28, 32, 0.49) 45.83%, #1C1C20 100%);
        display: flex;
        justify-content: center;
        padding: 12px 0;

        .button {
            width: $content-max-width;
            max-width: calc(100vw - 40px);
        }
    }

    @media (max-width: 389px) {
        .content {
            .logo-ru {
                height: 110px;

                img.name {
                    height: 30px;
                }

                .suffix-wrap {
                    height: 30px;

                    img.suffix {
                        height: 20px;
                        top: 6px;
                    }
                }
            }

            > .title {
                font-size: 17px;
                font-weight: 500;
                letter-spacing: -0.34px;
                white-space: pre-line;
                margin-bottom: 20px;
            }

            .feature-list {
                gap: 0;
                max-width: calc(100vw - 32px);

                .feature-item {
                    padding: 6px 0;
                }
            }
        }

        .redirect-btn {
            .button {
                max-width: calc(100vw - 32px);
            }
        }
    }

    @media (min-width: 744px) {
        padding-bottom: 172px;

        .content {
            .logo-ru {
                height: 146px;
                padding-top: 64px;
                align-items: flex-start;

                img.name {
                    height: 30px;
                }

                .suffix-wrap {
                    height: 30px;

                    img.suffix {
                        height: 20px;
                        top: 6px;
                    }
                }
            }

            > .title {
                white-space: pre-line;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0.5px;
            }

            .feature-list {
                .feature-item {
                    padding: 6px 0;
                }
            }
        }

        .redirect-btn {
            padding-bottom: 62px;
        }
    }
}

body.theme-light {
    .successful-payment-page {
        background-color: #FFF;

        .content {
            > .title {
                color: #141417;
            }

            .feature-list {
                .feature-item {
                    .text {
                        .title {
                            color: #141417;
                        }

                        .subtitle {
                            color: #81818F;
                        }
                    }
                }
            }
        }

        .redirect-btn {
            background: transparent;

            .button {
                box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06), 0 4px 8px 0 rgba(0, 0, 0, 0.14);
            }
        }
    }
}
</style>
