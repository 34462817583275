<template>
    <div class="page-text">
        <div class="page-text__title" v-if="title" v-html="title"></div>
        <div class="page-text__subtitle" v-if="subtitle" v-html="subtitle"></div>
    </div>
</template>

<script>
export default {
    name: "PageText",
    props: {
        title: String,
        subtitle: String
    }
};
</script>

<style lang="scss">
.page-text {
    $class: &;
    text-align: center;
    color: #ffffff;

    &__title {
        margin: 0 auto;
        padding: 0 15px;
        max-width: 350px;
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 51px;
        letter-spacing: -0.06em;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        #{$class}_medium-size & {
            font-size: 38px;
            line-height: 42px;
        }
    }

    &__subtitle {
        margin-top: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 120%;
        color: #ffffff;
    }
}
</style>
