<template>
    <div class="icon-check" :style="{ width: size + 'px', height: size + 'px'}">
        <svg v-if="active" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM11.7115 15.8143L17.4955 9.21808C17.6682 9.01843 17.7548 8.75859 17.7366 8.49527C17.7184 8.23195 17.5968 7.98651 17.3983 7.81251C17.1998 7.63852 16.9406 7.55009 16.6771 7.56651C16.4137 7.58294 16.1674 7.7029 15.9921 7.90021L10.5605 14.0947L7.95456 11.4858C7.76684 11.3001 7.51329 11.1963 7.24927 11.1969C6.98524 11.1975 6.73219 11.3026 6.54537 11.4892C6.35855 11.6758 6.25313 11.9287 6.25214 12.1927C6.25115 12.4567 6.35466 12.7104 6.54008 12.8984L9.52286 15.8846C9.65461 16.0446 9.81789 16.1758 10.0026 16.2701C10.1872 16.3643 10.3893 16.4195 10.5962 16.4322L10.6353 16.4317C10.8484 16.412 11.0549 16.3466 11.2405 16.2401C11.4262 16.1335 11.5869 15.9883 11.7115 15.8143Z" :fill="color"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="10.5" :stroke="color" stroke-opacity="0.6"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: "IconCheck",
    props: {
        active: Boolean,
        size: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: 'white'
        }
    }
};
</script>

<style scoped lang="scss">
    .icon-check {
        svg {
            path,
            circle {
                transition: 300ms;
            }
        }
    }
</style>
