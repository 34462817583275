import {createApp, h} from "vue";
import App from './App';
import router from "./router/index";
// import * as Sentry from "@sentry/vue";
// import {BrowserTracing} from "@sentry/tracing";
import {i18n} from "./locale/i18n";
import VueGtag from "vue-gtag";
import "./appsflyer/onelink.js";
import "./gtm/gtm.js";

const app = createApp({
    render: () => h(App)
});

app.config.globalProperties.isNewDesign = process.env.VUE_APP_NEWDESIGN === "true";
app.config.globalProperties.isTinkoff = process.env.VUE_APP_TINKOFF === "true";

const isLightBrowserTheme = window.matchMedia('(prefers-color-scheme: light)').matches;
app.config.globalProperties.isLightTheme = isLightBrowserTheme;
if (isLightBrowserTheme) {
    document.body.classList.add('theme-light');
}

let checkSpecialCode = function () {
    let url = new URL(window.location.href);
    let special = url.searchParams.get("special");
    if (special) {
        localStorage.setItem('special', special);
    }
    let click_id = url.searchParams.get("fbclid");
    if (click_id) {
        localStorage.setItem('click_id', click_id);
    }
};
let checkClearStorage = function () {
    let url = new URL(window.location.href);
    let clearStorage = url.searchParams.get("clear_storage");
    if (clearStorage) {
        let specialBackup = localStorage.getItem('special');
        localStorage.clear();
        if (specialBackup) {
            localStorage.setItem('special', specialBackup);
        }
    }
};
let checkStorageTimeout = function () {
    let lastTimestamp = localStorage.getItem('timestamp');
    let timestamp = new Date().getTime();
    let deadline = parseInt(lastTimestamp) + (24 * 60 * 60 * 1000);
    if (lastTimestamp && timestamp >= deadline) {
        let codeBackup = localStorage.getItem('code');
        let specialBackup = localStorage.getItem('special');
        let clickIdBackup = localStorage.getItem('click_id');
        localStorage.clear();
        if (codeBackup) {
            localStorage.setItem('code', codeBackup);
        }
        if (specialBackup) {
            localStorage.setItem('special', specialBackup);
        }
        if (clickIdBackup) {
            localStorage.setItem('click_id', clickIdBackup);
        }
    }
};
let checkCountryCode = function () {
    fetch("https://ipinfo.io/json")
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('countryCode', data.country);
        });
};

checkSpecialCode();
checkClearStorage();
checkStorageTimeout();
checkCountryCode();

app.use(i18n);

app.mixin({
    computed: {
        sourceIsApp() {
            if (this.$route.name === 'signup') {
                localStorage.setItem('source', this.$route.query.source);
            }
            return localStorage.getItem('source') === 'app';
        },
        tokenFromUrl() {
            return this.$route.query.token;
        },
    },
    methods: {
        async checkAuth() {
            const config = {
                method: 'POST',
                headers: {
                    'Auth-Token': this.tokenFromUrl
                }
            };
            try {
                const response = await fetch(process.env.VUE_APP_APPLEID_AUTH_CHECK_URI, config);
                const data = await response.json();
                return data.oauth_id;
            } catch (e) {
                console.log('checkAuth failed', e);
            }
        },
        routeTo: function (name) {
            this.$router.push({name: name, params: {code: this.$route.params.code}});
        },
        routeByStatus: function (name) {
            if (process.env.NODE_ENV === 'local') {
                this.routeTo(name);
                return;
            }
            if (localStorage.getItem('oauth_id')) {
                if (localStorage.getItem('status') === "su") {
                    localStorage.removeItem("subscribed");
                    this.routeTo('subscribed');
                } else {
                    this.routeTo('purchase');
                }
            } else if (name) {
                this.routeTo(name);
            }
        },
        routeIfCannotSubscribe: function () {
            if (process.env.NODE_ENV === 'local') {
                return;
            }
            if (!localStorage.getItem('oauth_id')) {
                this.routeTo('signup');
                return;
            }
            if (localStorage.getItem('status') === "su") {
                localStorage.removeItem("subscribed");
                this.routeTo('subscribed');
                return;
            }
        },
        getPrice: function (price, currencyCode) {
            let floatPrice = price / 100;
            switch (currencyCode) {
                case "rub":
                    return floatPrice + "₽";
                case "eur":
                    return "€" + floatPrice;
                case "usd":
                    return "$" + floatPrice;
                default:
                    return floatPrice + " " + currencyCode;
            }
        },
        loadPlan: function () {
            return new Promise((resolve, reject) => {
                let code = this.$route.params.code || localStorage.getItem('code');
                let oauth_id = localStorage.getItem('oauth_id');
                if (oauth_id) {
                    let body = {
                        oauth_id: oauth_id
                    };
                    if (code && code != 'undefined') {
                        body.code = code;
                    }
                    let fetchPlan = function (ipInfo) {
                        if (ipInfo) {
                            body.ip = ipInfo.ip;
                            body.postal_code = ipInfo.postal;
                            body.country_code = ipInfo.country;
                            body.city = ipInfo.city;
                        }
                        const request = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify(body)
                        };
                        fetch(process.env.VUE_APP_STRIPE_PLAN_REQUEST_URI, request)
                            .then(response => response.json())
                            .then(data => {
                                resolve(data);
                            });
                    };
                    this.getIpInfo()
                        .then(ipInfo => {
                            fetchPlan(ipInfo);
                        })
                        .catch(function (error) {
                            fetchPlan(false);
                        });
                } else {
                    reject({error: "not authorized"});
                }
            });
        },
        loadTinkoffPlan: function () {
            return new Promise((resolve, reject) => {
                let code = this.$route.params.code || localStorage.getItem('code');
                let oauth_id = '001764.0af9787d5bfd447ea437371f73e7d16c.1523';
                // var oauth_id = localStorage.getItem('oauth_id');
                let year_id = process.env.VUE_APP_TINKOFF_YEAR_ID;
                let month_id = process.env.VUE_APP_TINKOFF_MONTH_ID;
                if (oauth_id) {
                    let body = {
                        oauth_id: oauth_id
                    };
                    if (code && code != 'undefined') {
                        body.code = code;
                    }
                    const request = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(body)
                    };
                    fetch(process.env.VUE_APP_TINKOFF_PLAN_REQUEST_URI, request)
                        .then(response => response.json())
                        .then(json => {
                            let data = {};
                            json.data.forEach((e) => {
                                if (e.product_id == year_id) {
                                    data.year = e;
                                } else if (e.product_id == month_id) {
                                    data.month = e;
                                }
                            });
                            resolve(data);
                        });
                } else {
                    reject({error: "not authorized"});
                }
            });
        },
        subscribeTinkoffPlan: function (product_id, use_trial) {
            let oauth_id = localStorage.getItem('oauth_id');
            if (oauth_id) {
                let body = {oauth_id, product_id, use_trial};
                const request = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(body)
                };
                fetch(process.env.VUE_APP_TINKOFF_SUBSCRIBE_REQUEST_URI, request)
                    .then(response => response.json())
                    .then(data => {
                        if (data.result) {
                            window.location.href = data.payment_url;
                        } else if (data.code === 1001) {
                            // localStorage.setItem('status', "su");
                            // this.routeTo('subscribed');
                        } else {
                            // this.routeTo('payment-fail');
                        }
                    });
                /*
                .catch(error => {
                    this.routeTo('payment-fail');
                });
                */
            } else {
                this.routeTo('signup');
            }
        },
        loadRobokassaPlan: function () {
            return new Promise((resolve, reject) => {
                let code = this.$route.params.code || localStorage.getItem('code');
                let oauth_id = process.env.NODE_ENV === "local"
                    ? '001764.0af9787d5bfd447ea437371f73e7d16c.1523'
                    : localStorage.getItem('oauth_id');
                let year_id = process.env.VUE_APP_ROBOKASSA_YEAR_ID;
                let month_id = process.env.VUE_APP_ROBOKASSA_MONTH_ID;
                if (oauth_id) {
                    let body = {
                        oauth_id: oauth_id
                    };
                    if (code && code != 'undefined') {
                        body.code = code;
                    }
                    const request = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(body)
                    };
                    fetch(process.env.VUE_APP_ROBOKASSA_PLAN_REQUEST_URI, request)
                        .then(response => response.json())
                        .then(json => {
                            let data = {};
                            json.data.forEach((e) => {
                                if (e.product_id == year_id) {
                                    data.year = e;
                                } else if (e.product_id == month_id) {
                                    data.month = e;
                                }
                            });
                            resolve(data);
                        });
                } else {
                    reject({error: "not authorized"});
                }
            });
        },
        subscribeRobokassaPlan: async function (product_id, use_trial, consent) {
            let oauth_id = process.env.NODE_ENV === "local"
                ? '001764.0af9787d5bfd447ea437371f73e7d16c.1523'
                : localStorage.getItem('oauth_id');
            if (oauth_id) {
                let body = {oauth_id, product_id, use_trial, consent};
                const request = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(body)
                };
                const response = await fetch(process.env.VUE_APP_ROBOKASSA_SUBSCRIBE_REQUEST_URI, request);
                const data = await response.json();
                if (data.result) {
                    window.location.href = data.payment_url;
                } else if (data.code === 1001) {
                    // localStorage.setItem('status', "su");
                    // this.routeTo('subscribed');
                } else {
                    // this.routeTo('payment-fail');
                }
                /*
                .catch(error => {
                    this.routeTo('payment-fail');
                });
                */
            } else {
                this.routeTo('signup');
            }
        },
        getCountryCode: function () {
            return new Promise((resolve, reject) => {
                if (localStorage.getItem('countryCode') && localStorage.getItem('countryCode') !== '') {
                    resolve(localStorage.getItem('countryCode'));
                } else {
                    fetch("https://ipinfo.io/json")
                        .then(response => response.json())
                        .then(data => {
                            resolve(data.country);
                        })
                        .catch(function (error) {
                            reject(error);
                        });
                }
            });
        },
        getIpInfo: function () {
            return new Promise((resolve, reject) => {
                fetch("https://ipinfo.io/json")
                    .then(response => response.json())
                    .then(data => {
                        resolve(data);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        },
        checkSpecialCode: checkSpecialCode,
        createDefaultOnelinkIfNeeded: function () {
            if (localStorage.getItem('onelink')) {
                return;
            }
            let onelink = new URL(process.env.VUE_APP_ONELINK);
            if (process.env.VUE_APP_ONELINK_PID) {
                onelink.searchParams.set('pid', process.env.VUE_APP_ONELINK_PID);
            }
            if (process.env.VUE_APP_ONELINK_CAMPAIGN) {
                onelink.searchParams.set('c', process.env.VUE_APP_ONELINK_CAMPAIGN);
            }
            if (localStorage.getItem('oauth_id')) {
                onelink.searchParams.set('deep_link_value', localStorage.getItem('oauth_id'));
            }
            this.setToStorage("onelink", onelink);
        },
        setToStorage: function (key, value) {
            if (value) {
                localStorage.setItem(key, value);
            } else {
                localStorage.removeItem(key);
            }
            localStorage.setItem('timestamp', new Date().getTime().toString());
        },
        gtag_event: function (event) {
            if (process.env.NODE_ENV === "local") {
                return;
            }
            if (process.env.VUE_APP_GTAG_ID && process.env.VUE_APP_GTAG_ID != '') {
                this.$gtag.event(event);
            }
        },
    },
});

if (process.env.NODE_ENV == "production" || localStorage.special == process.env.VUE_APP_SPECIAL_TOKEN) {
    /*
        Sentry.init({
          app,
          dsn: "https://bb9df46c2db7449787e02960b6ae97de@o1118489.ingest.sentry.io/6152459",
          environment: process.env.NODE_ENV,
          integrations: [
            new BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router),
              tracingOrigins: [process.env.VUE_APP_HOST, /^\//],
            }),
          ],
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 0.0,
        });
    */

    app.use(router);
    if (process.env.VUE_APP_GTAG_ID && process.env.VUE_APP_GTAG_ID !== '') {
        app.use(VueGtag, {
            config: {id: process.env.VUE_APP_GTAG_ID}
        });
    }
    app.mount("#app");
} else if (process.env.NODE_ENV === "local") {
    app.use(router);
    app.mount("#app");
}
