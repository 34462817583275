<template>
    <div class="review">
        <div class="review__header">
            <div class="review__header-left">
                <img class="review__avatar" :src="`/assets/images/${imgName}.jpeg`" width="72" height="72" />
            </div>
            <div class="review__header-right">
                <div class="review__name" v-html="name"></div>
                <div class="review__post" v-html="post"></div>    
            </div>
        </div>
        <div class="review__text" v-html="text"></div>
    </div>
</template>

<script>
export default {
    name: "Review",
    props: {
        imgName: String,
        name: String,
        post: String,
        text: String
    }
};
</script>

<style lang="scss">
.review {
    margin: 0 20px;
    padding: 16px;
    background: #F4F5F5;
    border-radius: 15px;
    width: 100%;
    max-width: 675px;
    text-align: left;

    &__header {        
        display: flex;

        &-right {
            margin-left: 13px;
        }
    }

    &__avatar {
        margin: 0 auto;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 12px;
    }

    &__name {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #141417;
    }

    &__post {
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #A1A1AA;
    }

    &__text {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #141417;
    }
    
}
</style>
