<template>
    <div class="onboarding-page">
        <div class="onboarding-page__video">
            <slot name="video" />
        </div>
        <div class="onboarding-page__gradient"></div>
        <div class="onboarding-page__text" v-if="$slots.text">
            <slot name="text" />
        </div>
        <div class="onboarding-page__slider" v-if="$slots.slider"> 
            <slot name="slider"/>
        </div>
        <div class="onboarding-page__button"> 
            <slot name="button"/>
        </div>
        <div class="onboarding-page__links" v-if="$slots.links"> 
            <slot name="links"/>
        </div>
    </div>
</template>

<script>
 export default{
    name:"OnboardingPage",
    setup(props, { slots }) {
        const hasSlot = name => !!slots[name]
        return { hasSlot }
    }
 }
</script>

<style lang="scss">
.onboarding-page {
    $class: &;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &__video {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(54, 43, 103, 0) 0%, #04071a 100%);

        #{$class}_sign-in & {
            background: linear-gradient(180deg, rgba(54, 43, 103, 0) 0%, #04071a 69.57%);
        }
    }

    &__text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 130px;
    }

    &__slider {
        position: absolute;
        bottom: 130px;
        width: 100%;

        #{$class}_sign-in & {
            bottom: 155px;
        }
    }

    &__button {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 316px;
        height: 56px;

        #{$class}_sign-in & {
            bottom: 74px;
        }
    }

    &__links {
        position: absolute;
        bottom: 13px;
        width: 100%;
        height: 48px;
    }
}
</style>

