<template>
    <div class="logo">
        <img class="logo__img" src="/assets/images/logo.png" width="60" height="60" />
    </div>
</template>

<script>
export default {
    name: "Logo",
};
</script>

<style lang="scss">
.logo {
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
        display: block;
        margin: 0 auto;
        filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.12));
        -webkit-box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
        border-radius: 18px;
    }
}
</style>
