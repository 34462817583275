<template>
    <div class="links-block">
        <div class="links-block__text"> {{ $t("link.accept_text") }} </div>
        <a class="links-block__link" href="https://instories.com/privacy" target="_blank">
            {{ $t("link.privacy_policy") }}
        </a>
        <span class="links-block__and"> {{ $t("common.and") }} </span>
        <a class="links-block__link" href="https://instories.com/terms" target="_blank">
            {{ $t("link.terms_conditions") }}
        </a>
    </div>
</template>

<script>
export default {
    name: "LinksBlock",
};
</script>

<style lang="scss">
.links-block {
    $class: &;
    padding: 9px 15px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);

    &_isOneRow {
        padding: 16px 15px;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #81818F;
    }

    &_forTinkoff {
        padding-bottom: 0;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #7B7EA0;
    }

    &__text {
        padding: 0 4px;
    }

    &_dark & {
        color: #7B7EA0;
    }

    &__and {
        padding: 0 4px;
    }

    &__link {
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none !important;
        white-space: nowrap;

        #{$class}_dark &,
        #{$class}_forTinkoff & {
            color: #3997FE;
        }

        #{$class}_isOneRow & {
            color: #7464de;
        }
    }
}
</style>
