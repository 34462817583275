<template>
    <div v-if="isTinkoff" class="signup-page">
        <div class="bg-video">
            <video autoPlay loop muted :controls=false playsInline>
                <source src='/assets/video/instories-ru-bg1.mp4' type='video/mp4'/>
            </video>
        </div>
        <div class="bg-gradient"/>
        <div class="content">
            <div class="logo-ru">
                <img src="/assets/images/logo_white_ru.png" alt=""/>
            </div>
            <div class="title">{{ $t('signup.title') }}</div>
            <div class="description">{{ $t('signup.description') }}</div>
            <Button class="button_white" :title="$t('button.sign_in')" v-on:click="pressSignIn">
                <template #icon>
                    <IcApple/>
                </template>
            </Button>
        </div>
    </div>

    <!--
        <Page v-if="isTinkoff">
            <template v-slot:award>
                <AwardBlock/>
            </template>

            <template v-slot:logo>
                 <Logo/>
            </template>

            <template v-slot:title>
                <PageTitle :title="$t('text.page_title')"/>
            </template>

            <template v-slot:img>
                <ImageBlock/>
            </template>

            <template v-slot:button>
                <TextBlock :text="$t('text.auth')"/>
                <Button class="button_purple" :title="$t('button.sign_in_2')" v-on:click="pressSignIn"/>
            </template>
        </Page>
    -->

    <Page v-else-if="isNewDesign">
        <template v-slot:award>
            <AwardBlock/>
        </template>

        <template v-slot:logo>
            <Logo/>
        </template>

        <template v-slot:title>
            <PageTitle :title="$t('text.page_title')"/>
        </template>

        <template v-slot:img>
            <ImageBlock/>
        </template>

        <template v-slot:button>
            <TextBlock text="Login to Instories"/>
            <Button class="button_apple-white button_black" :title="$t('button.get_app')" v-on:click="pressSignIn"/>
        </template>
    </Page>

    <OnboardingPage v-else class="onboarding-page_sign-in">
        <template v-slot:video>
            <VideoItem name="slider_video_3"/>
        </template>

        <template v-slot:slider>
            <AwardsSlider/>
        </template>

        <template v-slot:button>
            <Button class="button_apple" :title="$t('button.sign_in')" v-on:click="pressSignIn"/>
        </template>

        <template v-slot:links>
            <LinksBlock/>
        </template>
    </OnboardingPage>
</template>

<script>
import AwardBlock from "@/components/AwardBlock";
import Page from "@/components/Page";
import Logo from "@/components/Logo";
import PageTitle from "@/components/PageTitle";
import ImageBlock from "@/components/ImageBlock";
import Button from "@/components/Button";
import OnboardingPage from "@/components/OnboardingPage";
import VideoItem from "@/components/VideoItem";
import AwardsSlider from "@/components/AwardsSlider";
import LinksBlock from "@/components/LinksBlock";
import TextBlock from "@/components/TextBlock";
import IcApple from "@/components/IcApple.vue";

export default {
    name: "Signup",
    components: {
        IcApple,
        AwardBlock,
        Page,
        Logo,
        PageTitle,
        ImageBlock,
        Button,
        OnboardingPage,
        VideoItem,
        AwardsSlider,
        LinksBlock,
        TextBlock
    },
    data: () => ({
        needAuth: true
    }),
    methods: {
        pressSignIn: function () {
            if (!this.needAuth) {
                this.routeTo('purchase');
                return;
            }
            AppleID.auth.signIn();
        },
        showOauth: function () {
            var code = this.$route.params.code || localStorage.getItem('code');
            var redirect = window.location.origin;
            if (code && code != 'undefined') {
                redirect += "/" + code + "/signup";
            } else {
                redirect += "/purchase";
                // redirect += "/signup";
            }
            var special = localStorage.getItem('special') || process.env.VUE_APP_SPECIAL_TOKEN;

            var state = {
                special: special,
                redirect: redirect
            };
            if (localStorage.getItem('click_id')) {
                state.click_id = localStorage.getItem('click_id');
            }
            AppleID.auth.init({
                clientId: process.env.VUE_APP_APPLEID_AUTH_CLIENT_ID,
                scope: "name email",
                redirectURI: process.env.VUE_APP_APPLEID_AUTH_REDIRECT_URI,
                state: JSON.stringify(state),
                usePopup: process.env.VUE_APP_APPLEID_USE_POPUP === "true"
            });
        },
    },
    created: async function () {
        let url = new URL(window.location.href);

        if (this.sourceIsApp && this.tokenFromUrl) {
            const oauth_id = await this.checkAuth();
            if (oauth_id) {
                localStorage.setItem('oauth_id', oauth_id);
                this.needAuth = false;
            }
        }

        let oauth_id = url.searchParams.get("oauth_id") || localStorage.getItem('oauth_id');
        var status = url.searchParams.get("status") || localStorage.getItem('status');
        if (this.isTinkoff) {
            status = url.searchParams.get("robokassa_status") || localStorage.getItem('status');
            // status = url.searchParams.get("tinkoff_status") || localStorage.getItem('status');
        }
        if (oauth_id && status) {
            var onelink = new URL(localStorage.getItem('onelink'));
            onelink.searchParams.set("deep_link_value", oauth_id);
            this.setToStorage("status", status);
            this.setToStorage("oauth_id", oauth_id);
            this.setToStorage("onelink", onelink.href);
            this.routeByStatus();
            return;
        }
    },
    mounted: async function () {
        this.gtag_event('open_' + this.$route.name);

        if (!this.needAuth) {
            return;
        }

        let appleScriptId = "appleScriptId";
        if (document.getElementById(appleScriptId) === null) {
            let appleScript = document.createElement('script');
            appleScript.setAttribute('src', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/' + this.$t("apple.script_lang") + '/appleid.auth.js');
            appleScript.setAttribute('id', appleScriptId);
            document.head.appendChild(appleScript);

            document.addEventListener('AppleIDSignInOnSuccess', (data) => {
                var body = data.detail;
                body.special = localStorage.getItem('special') || process.env.VUE_APP_SPECIAL_TOKEN;
                if (localStorage.getItem('click_id')) {
                    body.click_id = localStorage.getItem('click_id');
                }
                const request = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(body)
                };
                fetch(process.env.VUE_APP_APPLEID_AUTH_REQUEST_URI, request)
                    .then(response => response.json())
                    .then(data => {
                        this.createDefaultOnelinkIfNeeded();
                        var onelink = new URL(localStorage.getItem('onelink'));
                        onelink.searchParams.set("deep_link_value", data.oauth_id);
                        this.setToStorage("onelink", onelink.href);
                        var status = data.status;
                        if (this.isTinkoff) {
                            status = data.robokassa_status;
                            // status = data.tinkoff_status;
                        }
                        if (data.oauth_id && status) {
                            this.setToStorage("status", status);
                            this.setToStorage("oauth_id", data.oauth_id);
                        }
                        this.routeByStatus('signup-fail');
                    });
            });

            document.addEventListener('AppleIDSignInOnFailure', (error) => {
                console.log('AppleIDSignInOnFailure', error);
            });

            appleScript.onload = this.showOauth;
        } else {
            this.showOauth();
        }
    },
};

</script>

<style lang="scss">
.signup-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #151518;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bg-video {
        position: absolute;
        z-index: 1;
        inset: 0;
        display: flex;
        justify-content: center;

        video {
            object-fit: cover;
            width: 100%;
            max-width: $container-max-width;
            height: calc(100% - 160px);
            object-position: right 35% top 0;
            position: relative;
            z-index: 1;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            z-index: 2;
        }
    }

    .bg-gradient {
        position: absolute;
        z-index: 2;
        inset: auto 0 0 0;
        height: 380px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.85) 36.46%, #000 82.29%);
    }

    .content {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: $content-max-width;
        padding: 20px 0 106px;

        .logo-ru {
            flex-grow: 1;
            display: flex;

            img {
                height: 24px;
            }
        }

        .title {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #EFEFF2;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.5px;
            padding-bottom: 12px;
            white-space: pre-line;
        }

        .description {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #A1A1AA;
            text-align: center;
            font-size: 17px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.3px;
            padding-bottom: 32px;
        }

        .button {
            width: 100%;
            max-width: calc(100vw - 40px);
        }

        @media (max-width: 389px) {
            .title {
                font-size: 17px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.34px;
            }

            .description {
                font-size: 14px;
                line-height: 20px;
            }
        }

        @media (min-width: 744px) {
            padding-bottom: 62px;
        }
    }
}
</style>
